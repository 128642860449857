import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Dropdown, Input, Menu, Space } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";

import { oneFilter, oneSerpFilter } from "@/api/filters";
import Arrow from "@/icons/filters/arrow.svg";
import MenuItem from "@/icons/filters/menu-item.svg";
import MenuItemSelected from "@/icons/filters/menu-item-selected.svg";
import {
  removeDynamicFilter,
  setDynamicFilter,
  setFiltersData,
} from "@/store/filters";
import { useAppDispatch } from "@/store";
import { useSuggest } from "@/modules/shared/hooks";
import RadioButton from "@/icons/modules/shared/radio-button.svg";
import {
  deleteDefaultSheetFilter,
  setDefaultSheetFilter,
} from "@/store/sheets";
import { VirtualMenu } from "@/modules/shared/atoms/virtual-menu";
import { DiapasonPicker } from "@/modules/shared/atoms/diapason-picker";
import {
  RELATED_FILTERS,
  RELATED_SERP_FILTERS,
} from "@/consts/related-filters";

import type {
  Dispatch,
  SetStateAction,
  ChangeEvent,
  CSSProperties,
} from "react";
import type { TState } from "@/store/types";

import "./page-filter-select.scss";

type PageFilterSelectProps = {
  title?: string;
  searchText?: string;
  value: string | string[];
  options: {
    id: string;
    value: string;
    description: string | null;
  }[];
  mode?: "tags" | "multiple";
  type?: string;
  attribute: string;
  setFilters: Dispatch<SetStateAction<Record<string, string | string[]>>>;
  asyncSearch?: boolean;
  icon?: React.ReactNode;
  iconAsc?: React.ReactNode;
  iconDesc?: React.ReactNode;
  open?: boolean;
  handleOpenChange?: (open: boolean) => void;
  sorting?: {
    onSelect: ({ selectedKeys }: { selectedKeys: string[] }) => void;
    selectedKey: string[];
    onDeselect: () => void;
  };
  withoutFooter?: boolean;
  className?: string;
  virtual?: boolean;
  style?: CSSProperties;
  height?: number;
};

const PageFilterSelect = (props: PageFilterSelectProps) => {
  const {
    value,
    options: propsOptions,
    mode,
    attribute,
    type,
    setFilters,
    title,
    searchText,
    asyncSearch,
    icon,
    iconAsc,
    iconDesc,
    open: openProps,
    handleOpenChange,
    sorting,
    withoutFooter,
    className,
    virtual,
    style,
    height,
  } = props;

  const { dynamicFilters, filtersData } = useSelector(
    (state: TState) => state.filters
  );

  const { date } = useSelector((state: TState) => state.filters);

  const { filterConfigs } = useSelector((state: TState) => state.user);

  const filterGroups = useMemo(() => {
    const groups = filterConfigs.find(
      (filterConfig) => filterConfig?.id === attribute
    );
    let result: any[] = [];

    if (groups) {
      result = groups?.groups || [];
    }

    return result.map((filterGroup) => ({
      ...filterGroup,
    }));
  }, [JSON.stringify(filterConfigs)]);

  const [getFilter] = useLazyQuery(oneFilter);

  const [getSerpFilter] = useLazyQuery(oneSerpFilter);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const location = useLocation();
  const hash = location.hash?.slice(1);

  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [localSortingKeys, setLocalSortingKeys] = useState(
    sorting?.selectedKey
  );

  const [openedGroups, setOpenedGroups] = useState<string[]>([]);

  const changeOpenedGroups = (group: string) => {
    const result = [...openedGroups];

    if (openedGroups.includes(group)) {
      result.splice(openedGroups.indexOf(group), 1);
    } else {
      result.push(group);
    }
    setOpenedGroups(result);
  };

  useEffect(() => {
    setLocalSortingKeys(sorting?.selectedKey);
  }, [sorting?.selectedKey]);

  const handleSelectSorting = ({ selectedKeys }: { selectedKeys: string[] }) =>
    setLocalSortingKeys(selectedKeys);

  const handleDeselectSorting = () => setLocalSortingKeys([]);

  const { data } = useSuggest(attribute, search, 100, null, asyncSearch);

  const handleSelect = ({ key }: { key: string }) => {
    setFilters((prevState) => {
      if (mode !== "multiple") {
        return {
          ...prevState,
          [attribute]: key,
        };
      }

      if (!prevState[attribute]) {
        return {
          ...prevState,
          [attribute]: [key],
        };
      }

      return {
        ...prevState,
        [attribute]: prevState[attribute].includes(key)
          ? (prevState[attribute] as string[]).filter((item) => item !== key)
          : [...(prevState[attribute] as string[]), key],
      };
    });
  };

  const options = useMemo(
    () =>
      data?.suggest?.values?.length
        ? data?.suggest?.values.map(({ id, value: optionValue }) => ({
            label: optionValue,
            key: id,
            icon: value?.includes(id) ? <MenuItemSelected /> : <MenuItem />,
          }))
        : filterGroups.length
        ? [
            ...filterGroups.map(
              (filterGroup: {
                title: string;
                values: string[];
                selected: boolean;
                opened: boolean;
              }) => ({
                key: filterGroup.title,
                type: "group",
                label: (
                  <div className="page-filter-select__group__title">
                    <div
                      className="page-filter-select__group__title__start"
                      onClick={() => {
                        const newValues = propsOptions.filter(
                          ({ value: optionValue }) =>
                            filterGroups.every((item) =>
                              item.values.includes(optionValue.trim())
                            )
                        );

                        const missingIds = newValues.filter(
                          (item) => !value?.includes(item.id)
                        );

                        if (missingIds.length > 0) {
                          missingIds.forEach(({ id }) => {
                            handleSelect({ key: id });
                          });
                        } else {
                          newValues.forEach(({ id }) => {
                            handleSelect({ key: id });
                          });
                        }
                      }}
                    >
                      {propsOptions
                        .filter(({ value: optionValue }) =>
                          filterGroups.every((item) =>
                            item.values?.includes(optionValue.trim())
                          )
                        )
                        .every(({ id }) => value?.includes(id)) ? (
                        <MenuItemSelected />
                      ) : (
                        <MenuItem />
                      )}
                      {filterGroup.title}
                    </div>
                    <div
                      className={[
                        "page-filter-select__group__title__arrow",
                        openedGroups.includes(filterGroup.title)
                          ? "page-filter-select__group__title__arrow--open"
                          : null,
                      ].join(" ")}
                      onClick={() => {
                        changeOpenedGroups(filterGroup.title);
                      }}
                    >
                      <Arrow />
                    </div>
                  </div>
                ),
                icon: null,
                children: openedGroups.includes(filterGroup.title)
                  ? propsOptions
                      .filter(({ value: optionValue }) =>
                        filterGroup.values.includes(optionValue.trim())
                      )
                      .map(({ id, value: optionValue }) => {
                        if (Array.isArray(value)) {
                          return {
                            label: optionValue,
                            key: id,
                            icon: value?.includes(id) ? (
                              <MenuItemSelected />
                            ) : (
                              <MenuItem />
                            ),
                            selected: value.includes(id),
                          };
                        }

                        return {
                          label: optionValue,
                          key: id,
                          icon:
                            value === id ? <MenuItemSelected /> : <MenuItem />,
                          selected: value === id,
                        };
                      })
                      .sort((a, b) => {
                        if (a.selected && !b.selected) {
                          return -1;
                        }

                        if (!a.selected && b.selected) {
                          return 1;
                        }

                        return 0;
                      })
                  : [],
              })
            ),
            {
              key: "other",
              type: "group",
              label: (
                <div className="page-filter-select__group__title">
                  <div
                    className="page-filter-select__group__title__start"
                    onClick={() => {
                      const newValues = propsOptions.filter(
                        ({ value: optionValue }) =>
                          filterGroups.every(
                            (item) => !item.values.includes(optionValue.trim())
                          )
                      );

                      const missingIds = newValues.filter(
                        (item) => !value?.includes(item.id)
                      );

                      if (missingIds.length > 0) {
                        missingIds.forEach(({ id }) => {
                          handleSelect({ key: id });
                        });
                      } else {
                        newValues.forEach(({ id }) => {
                          handleSelect({ key: id });
                        });
                      }
                    }}
                  >
                    {propsOptions
                      .filter(({ value: optionValue }) =>
                        filterGroups.every(
                          (item) => !item.values.includes(optionValue.trim())
                        )
                      )
                      .every(({ id }) => value?.includes(id)) ? (
                      <MenuItemSelected />
                    ) : (
                      <MenuItem />
                    )}
                    {t("shared.no-category")}
                  </div>
                  <div
                    className={[
                      "page-filter-select__group__title__arrow",
                      openedGroups.includes("other")
                        ? "page-filter-select__group__title__arrow--open"
                        : null,
                    ].join(" ")}
                    onClick={() => {
                      changeOpenedGroups("other");
                    }}
                  >
                    <Arrow />
                  </div>
                </div>
              ),
              icon: null,
              children: openedGroups.includes("other")
                ? propsOptions
                    .filter(({ value: optionValue }) =>
                      filterGroups.every(
                        (item) => !item.values.includes(optionValue.trim())
                      )
                    )
                    .map(({ id, value: optionValue }) => {
                      if (Array.isArray(value)) {
                        return {
                          label: optionValue,
                          key: id,
                          icon: value.includes(id) ? (
                            <MenuItemSelected />
                          ) : (
                            <MenuItem />
                          ),
                          selected: value.includes(id),
                        };
                      }

                      return {
                        label: optionValue,
                        key: id,
                        icon:
                          value === id ? <MenuItemSelected /> : <MenuItem />,
                        selected: value === id,
                      };
                    })
                    .sort((a, b) => {
                      if (a.selected && !b.selected) {
                        return -1;
                      }

                      if (!a.selected && b.selected) {
                        return 1;
                      }

                      return 0;
                    })
                : [],
            },
          ]
        : propsOptions
            .map(({ id, value: optionValue }) => {
              if (Array.isArray(value)) {
                return {
                  label: optionValue,
                  key: id,
                  icon: value.includes(id) ? (
                    <MenuItemSelected />
                  ) : (
                    <MenuItem />
                  ),
                  selected: value.includes(id),
                };
              }

              return {
                label: optionValue,
                key: id,
                icon: value === id ? <MenuItemSelected /> : <MenuItem />,
                selected: value === id,
              };
            })
            .sort((a, b) => {
              if (a.selected && !b.selected) {
                return -1;
              }

              if (!a.selected && b.selected) {
                return 1;
              }

              return 0;
            }),
    [
      propsOptions,
      value,
      search,
      data,
      JSON.stringify(filterGroups),
      openedGroups,
    ]
  );

  const searchedOptions = useMemo(() => {
    let result = [];

    if (filterGroups.length) {
      result = options.map((option: any) => ({
        ...option,
        children: option.children.filter((child: any) =>
          child.label.toLowerCase().includes(search.toLowerCase())
        ),
      }));
    } else {
      result = [...options].filter((option: any) =>
        option.label.toLowerCase().includes(search?.toLowerCase())
      );
    }

    return result;
  }, [options]);

  const sortIcon = useMemo(() => {
    let result = icon;

    if (sorting?.selectedKey[0] === "ASC") {
      result = iconAsc;
    } else if (sorting?.selectedKey[0] === "DESC") {
      result = iconDesc;
    } else if (Object.keys(dynamicFilters).indexOf(attribute) !== -1) {
      result = iconAsc;
    }

    return result;
  }, [sorting]);

  const min = useMemo(
    () => Math.floor(options?.[0] ? Number(options[0].label) : 0),
    [options]
  );
  const max = useMemo(
    () => Math.ceil(options?.[1] ? Number(options[1].label) : 1),
    [options]
  );
  const [rangeValue, setRangeValue] = useState(
    value?.[0] && value?.[1] ? [Number(value[0]), Number(value[1])] : [min, max]
  );

  const handleSubmit = () => {
    if (localSortingKeys?.length) {
      sorting?.onSelect({ selectedKeys: localSortingKeys });
    } else {
      sorting?.onDeselect();
    }

    if (value?.length) {
      dispatch(setDynamicFilter({ key: attribute, value }));
    } else {
      dispatch(removeDynamicFilter({ key: attribute }));
    }

    if (hash === "default") {
      if (value?.length) {
        dispatch(
          setDefaultSheetFilter({
            key: attribute,
            value: value ? (Array.isArray(value) ? value : [value]) : [],
          })
        );
      } else {
        dispatch(deleteDefaultSheetFilter(attribute));
      }
    }

    if (handleOpenChange) {
      handleOpenChange(false);
    } else {
      setOpen(false);
    }

    if (Object.keys(RELATED_FILTERS).indexOf(attribute) !== -1) {
      getFilter({
        variables: {
          dates: {
            from: date.startDate?.format("YYYY-MM-DD") || "",
            to: date.endDate?.format("YYYY-MM-DD") || "",
          },
          id: RELATED_FILTERS[attribute],
          filters: [{ id: attribute, values: value }],
        },
        fetchPolicy: "no-cache",
      }).then((res) => {
        const updFilters = filtersData?.filters
          ? {
              filters: filtersData.filters.map((item) => ({
                ...item,
                values:
                  item.id === res.data.getFilter.id
                    ? res.data.getFilter.values
                    : item.values,
              })),
            }
          : undefined;
        dispatch(setFiltersData(updFilters));
      });
    } else if (Object.keys(RELATED_SERP_FILTERS).indexOf(attribute) !== -1) {
      getSerpFilter({
        variables: {
          dates: {
            from: date.startDate?.format("YYYY-MM-DD") || "",
            to: date.endDate?.format("YYYY-MM-DD") || "",
          },
          id: RELATED_SERP_FILTERS[attribute],
          filters: [{ id: attribute, values: value }],
        },
        fetchPolicy: "no-cache",
      }).then((res) => {
        const updFilters = filtersData?.filters
          ? {
              filters: filtersData.filters.map((item) => ({
                ...item,
                values:
                  item.id === res.data.getSerpFilter.id
                    ? res.data.getSerpFilter.values
                    : item.values,
              })),
            }
          : undefined;
        dispatch(setFiltersData(updFilters));
      });
    }
  };

  const handleReset = () => {
    handleDeselectSorting();
    sorting?.onDeselect();

    if (handleOpenChange) {
      handleOpenChange(false);
    } else {
      setOpen(false);
    }

    if (Object.keys(RELATED_FILTERS).indexOf(attribute) !== -1) {
      getFilter({
        variables: {
          dates: {
            from: date.startDate?.format("YYYY-MM-DD") || "",
            to: date.endDate?.format("YYYY-MM-DD") || "",
          },
          id: RELATED_FILTERS[attribute],
          filters: [{ id: attribute, values: [] }],
        },
        fetchPolicy: "no-cache",
      }).then((res) => {
        const updFilters = filtersData?.filters
          ? {
              filters: filtersData.filters.map((item) => ({
                ...item,
                values:
                  item.id === res.data.getFilter.id
                    ? res.data.getFilter.values
                    : item.values,
              })),
            }
          : undefined;
        dispatch(setFiltersData(updFilters));
      });
    } else if (Object.keys(RELATED_SERP_FILTERS).indexOf(attribute) !== -1) {
      getSerpFilter({
        variables: {
          dates: {
            from: date.startDate?.format("YYYY-MM-DD") || "",
            to: date.endDate?.format("YYYY-MM-DD") || "",
          },
          id: RELATED_SERP_FILTERS[attribute],
          filters: [{ id: attribute, values: [] }],
        },
        fetchPolicy: "no-cache",
      }).then((res) => {
        const updFilters = filtersData?.filters
          ? {
              filters: filtersData.filters.map((item) => ({
                ...item,
                values:
                  item.id === res.data.getSerpFilter.id
                    ? res.data.getSerpFilter.values
                    : item.values,
              })),
            }
          : undefined;
        dispatch(setFiltersData(updFilters));
      });
    }

    setFilters((prevState) => {
      const localValue: string | string[] = Array.isArray(prevState[attribute])
        ? []
        : "";
      dispatch(removeDynamicFilter({ key: attribute }));

      if (hash === "default") {
        dispatch(deleteDefaultSheetFilter(attribute));
      }

      return {
        ...prevState,
        [attribute]: localValue,
      };
    });

    setRangeValue(
      value?.[0] && value?.[1]
        ? [Number(value[0]), Number(value[1])]
        : [min, max]
    );
  };

  const handleSearch = (input: ChangeEvent<HTMLInputElement>) =>
    setSearch(input.target.value);

  const sortingOptions = [
    {
      key: "ASC",
      label: t("modules.atoms.filter-select.sort-asc"),
      icon: <RadioButton />,
    },
    {
      key: "DESC",
      label: t("modules.atoms.filter-select.sort-desc"),
      icon: <RadioButton />,
    },
  ];

  const isRange = type === "RANGE";
  const isSearch = type === "TEXT" && !asyncSearch;

  const dropdownRender = (menus: React.ReactNode) => (
    <div className="page-filter-select__dropdown">
      {propsOptions?.length > 10 || (asyncSearch && !isSearch) ? (
        <div className="page-filter-select__dropdown-header">
          <Input
            onChange={handleSearch}
            value={search}
            placeholder={
              searchText || title
                ? searchText ||
                  `${t("modules.atoms.filter-select.search-by")} ${title}`
                : t("modules.atoms.filter-select.search")
            }
          />
        </div>
      ) : null}
      {sorting && (
        <>
          <p className="page-filter-select__dropdown-section-title">
            {t("modules.atoms.filter-select.sorting")}
          </p>
          <Menu
            selectable
            className="page-filter-select__dropdown-sorting"
            items={sortingOptions}
            onDeselect={handleDeselectSorting}
            onSelect={handleSelectSorting}
            selectedKeys={localSortingKeys}
          />
          {options.length && !isRange && !isSearch ? (
            <>
              <div className="page-filter-select__dropdown-section-divider" />
              <p className="page-filter-select__dropdown-section-title">
                {t("modules.atoms.filter-select.filtering")}
              </p>
            </>
          ) : null}
        </>
      )}
      {menus}
      {!withoutFooter && (
        <div className="page-filter-select__dropdown-footer">
          <Button htmlType="submit" onClick={handleSubmit} type="primary">
            {t("modules.atoms.filter-select.ok")}
          </Button>
          <Button className="reset" htmlType="submit" onClick={handleReset}>
            {t("modules.atoms.filter-select.reset-filter")}
          </Button>
        </div>
      )}
    </div>
  );

  const onOpenChange = (isOpen: boolean) => {
    if (handleOpenChange) {
      handleOpenChange(isOpen);
    } else {
      setOpen(isOpen);
    }
  };

  if (isRange) {
    const onChange = (val: number[]) => {
      setRangeValue(val);
    };

    const handleRangePickerChange = (val: number[]) => {
      setFilters((prevState) => ({
        ...prevState,
        [attribute]: [String(val[0]), String(val[1])],
      }));
    };

    const menu = (
      <>
        <p className="page-filter-select__dropdown-section-title">
          {t("modules.atoms.filter-select.amount")}
        </p>
        <DiapasonPicker
          max={max}
          min={min}
          onChange={onChange}
          onChangeComplete={handleRangePickerChange}
          value={
            rangeValue?.[0] && rangeValue?.[1]
              ? [Number(rangeValue[0]), Number(rangeValue[1])]
              : [min, max]
          }
        />
      </>
    );

    return (
      <Dropdown
        destroyPopupOnHide
        className={className ? `table-dropdown ${className}` : "table-dropdown"}
        dropdownRender={dropdownRender}
        onOpenChange={onOpenChange}
        open={typeof openProps === "boolean" ? openProps : open}
        overlay={menu}
        placement="bottom"
        trigger={["click"]}
      >
        <Button
          style={style}
          className={`page-filter-select${
            sortIcon ? " page-filter-select__with-icon" : ""
          }`}
        >
          {sortIcon ? (
            <div className="page-filter-select-icon-holder">{sortIcon}</div>
          ) : (
            <Space>
              {title}
              <Arrow />
            </Space>
          )}
        </Button>
      </Dropdown>
    );
  }

  if (isSearch) {
    const handleSearchChange = (input: ChangeEvent<HTMLInputElement>) => {
      setFilters((prevState) => ({
        ...prevState,
        [attribute]: [input.target.value],
      }));
    };

    const menu = (
      <>
        <p className="page-filter-select__dropdown-section-title">
          {t("modules.atoms.filter-select.filtration-by-search")}
        </p>
        <Input
          onChange={handleSearchChange}
          onPressEnter={handleSubmit}
          placeholder={t("modules.atoms.filter-select.search")}
          value={value?.[0] || ""}
        />
      </>
    );

    return (
      <Dropdown
        destroyPopupOnHide
        className={className ? `table-dropdown ${className}` : "table-dropdown"}
        dropdownRender={dropdownRender}
        onOpenChange={onOpenChange}
        open={typeof openProps === "boolean" ? openProps : open}
        overlay={menu}
        overlayClassName={height ? "table-dropdown" : undefined}
        placement="bottom"
        trigger={["click"]}
      >
        <Button
          style={style}
          className={`page-filter-select${
            sortIcon ? " page-filter-select__with-icon" : ""
          }`}
        >
          {sortIcon ? (
            <div className="page-filter-select-icon-holder">{sortIcon}</div>
          ) : (
            <Space>
              {title}
              <Arrow />
            </Space>
          )}
        </Button>
      </Dropdown>
    );
  }

  if (virtual) {
    const menu = (
      <Menu
        multiple
        selectable
        defaultOpenKeys={["sub1", "sub2"]}
        mode="vertical"
        selectedKeys={value ? (Array.isArray(value) ? value : [value]) : []}
      >
        <VirtualMenu height={height}>
          {filterGroups.length
            ? searchedOptions.map((option: any) => (
                <Menu.ItemGroup key={option.key} title={option.label}>
                  <VirtualMenu>
                    {option.children?.map((child: any) => (
                      <Menu.Item
                        key={child.key}
                        icon={child.icon}
                        onClick={() => handleSelect({ key: child.key })}
                        title={child.label}
                        className={
                          (value
                            ? Array.isArray(value)
                              ? value
                              : [value]
                            : []
                          ).includes(child.key)
                            ? "ant-dropdown-menu-item-selected"
                            : undefined
                        }
                      >
                        {child.label}
                      </Menu.Item>
                    ))}
                  </VirtualMenu>
                </Menu.ItemGroup>
              ))
            : searchedOptions.map((item: any) => (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  onClick={() => handleSelect({ key: item.key })}
                  title={item.label}
                  className={
                    (value
                      ? Array.isArray(value)
                        ? value
                        : [value]
                      : []
                    ).includes(item.key)
                      ? "ant-dropdown-menu-item-selected"
                      : undefined
                  }
                >
                  {item.label}
                </Menu.Item>
              ))}
        </VirtualMenu>
      </Menu>
    );

    return (
      <Dropdown
        destroyPopupOnHide
        className={className ? `table-dropdown ${className}` : "table-dropdown"}
        dropdownRender={dropdownRender}
        onOpenChange={onOpenChange}
        open={typeof openProps === "boolean" ? openProps : open}
        overlay={menu}
        placement="bottom"
        trigger={["click"]}
      >
        <Button
          style={style}
          className={`page-filter-select${
            sortIcon ? " page-filter-select__with-icon" : ""
          }`}
        >
          {sortIcon ? (
            <div className="page-filter-select-icon-holder">{sortIcon}</div>
          ) : (
            <Space>
              {title}
              <Arrow />
            </Space>
          )}
        </Button>
      </Dropdown>
    );
  }

  return (
    <Dropdown
      className={className}
      dropdownRender={dropdownRender}
      onOpenChange={onOpenChange}
      open={typeof openProps === "boolean" ? openProps : open}
      overlayClassName={height ? "table-dropdown" : undefined}
      placement="bottom"
      trigger={["click"]}
      menu={{
        items: searchedOptions,
        multiple: true,
        selectable: true,
        selectedKeys: Array.isArray(value) ? value : [value],
        onClick: handleSelect,
      }}
    >
      <Button
        style={style}
        className={`page-filter-select${
          sortIcon ? " page-filter-select__with-icon" : ""
        }`}
      >
        {sortIcon ? (
          <div className="page-filter-select-icon-holder">{sortIcon}</div>
        ) : (
          <Space>
            {title}
            <Arrow />
          </Space>
        )}
      </Button>
    </Dropdown>
  );
};

export { PageFilterSelect };
